import React, {Component} from 'react';
import withCharactersAPI from '../../api/withCharactersAPI';
import ChineseCharFormView from '../../views/ChineseCharFormView';
import NewCharacterDialogContext from '../../dialogs/NewCharacterDialog/NewCharacterDialogContext';
import { ChaptersDataContext } from '../../../app-data/ChapterData';
import styles from './AddCharacterOverviewItem.scss';
import { CSSTransition } from 'react-transition-group';

class AddCharacterOverviewItem extends Component {
    saveNewCharacter = (data) => {
        data.chapters = [this.chaptersDataContext.selectedChapter];
        this.props.addChineseCharacter(data).then(res => {
            console.log(res);
        });
    };

    onToggle = () => {
        console.log('close the new one');
        this.context.toggleIsExiting();
    };

    render() {
        return (
            <ChaptersDataContext.Consumer>
                {
                    chaptersDataContext => {
                        this.chaptersDataContext = chaptersDataContext;
                        return <NewCharacterDialogContext.Consumer>
                            {
                                context => {
                                    console.log(context);
                                    this.context = context;
                                    return <CSSTransition
                                        in={context.open}
                                        timeout={5000}
                                        classNames={{
                                            appear: styles.appear,
                                            appearActive: styles.appearActive,
                                            enter: styles.enter,
                                            enterActive: styles.enterActive,
                                            enterActiveDone: styles.enterActiveDone,
                                            exit: styles.exit,
                                            exitActive: styles.exitActive,
                                            exitDone: styles.exitDone
                                        }}
                                        appear
                                    >
                                        <ChineseCharFormView
                                            className={styles.newField}
                                            onCancel={() => {
                                            }}
                                            onSave={this.saveNewCharacter}
                                            onToggle={context.toggle}
                                            isEditMode={true}
                                            hasDeleteBtn={false}
                                        />
                                    </CSSTransition>
                                }
                            }
                        </NewCharacterDialogContext.Consumer>
                    }
                }
            </ChaptersDataContext.Consumer>

        )
    }
}

export default withCharactersAPI(AddCharacterOverviewItem);