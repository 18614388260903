import {BASE_URL} from "../app-data/ChapterData";

export function getChapterCharactersUrl(chapterId) {
    return `${BASE_URL}/chapters/${chapterId}/chinese-chars`;
}

export function putChineseCharactersUrl(id) {
    return `${BASE_URL}/chinese-chars/${id}`;
}

export function postChineseCharactersUrl() {
    return `${BASE_URL}/chinese-chars/`;
}

export function deleteChineseCharactersUrl(id) {
    return `${BASE_URL}/chinese-chars/${id}`;
}
