import React, { Component } from 'react';
import NewCharacterDialogContext from './NewCharacterDialogContext';

class NewCharacterDialogProvider extends Component {
    state = {
        open: false,
        isExiting: false,
        toggle: () => {
            this.setState({open: !this.state.open})
        },
        toggleIsExiting: () => {
            this.setState({isExiting: !this.state.isExiting})
        }
    };

    render() {
        return <NewCharacterDialogContext.Provider value={this.state}>
            {this.props.children}
        </NewCharacterDialogContext.Provider>
    }
}

export default NewCharacterDialogProvider;