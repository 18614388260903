/** @jsxImportSource @emotion/react */
import React from 'react';
import CharacterRow from '../components/CharacterRow';
import ChapterSelector from '../components/ChapterSelector';
import { css } from '@emotion/react';
import { useState } from 'react';
import tw from 'twin.macro';
import { Button } from '@mui/material';

const CharactersManager = () => {
  const [characters, setCharacters] = useState([]);

  const buttonStyle = () => css`${tw`my-8 border`}`;

  let [currentChapters, setChapters] = useState([]);

  let chapterSelectorRef = React.createRef();

  const onResultsFound = (results) => {
    if (results && results.length === 0) return;
    setCharacters(current => {
        return results
    });
  }

  const onChapterSelected = (chapter) => {
    setChapters([chapter]);
  }

  const refreshCharacters = () => {
    chapterSelectorRef.current.refreshChapterCharacters();
  }

  return (
    <section>
      <h1>Characters Manager</h1>
      <section>
        <ChapterSelector
          ref={chapterSelectorRef}
          onResultsFound={onResultsFound}
          onChapterSelected={onChapterSelected}
        />

      </section>
      { (currentChapters && currentChapters.length > 0) &&
        <div css={buttonStyle()}>
          <Button variant="contained" onClick={refreshCharacters}>Refresh</Button>
          <h1><strong>Add new character Form:</strong></h1>
          <CharacterRow chapters={currentChapters} create onSuccess={refreshCharacters}/>
        </div>
      }
      <section>
        { characters.map((character) =>
          <CharacterRow
              key={character.id}
              onSuccess={refreshCharacters}
            { ...character}
          />
        )}
      </section>
    </section>
  )
}

export default CharactersManager;