import * as firebase from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import {clearUser, user as currentUser, userState} from '../../states/user';
import {BASE_URL} from '../../app-data/ChapterData';
import {getRequestHeader} from '../login/LoginForm';

export let initializedFirebaseApp = undefined;

export const initFirebase = async () => {
    const config = {
        apiKey: 'AIzaSyBaG17YXLAMY5TCF4yhIRRO5aISY7N0o7g',
        authDomain: 'api-ml-accounts.firebaseapp.com',
        databaseURL: 'https://api-ml-accounts.firebaseio.com',
        projectId: 'api-ml-accounts',
        storageBucket: 'api-ml-accounts.appspot.com',
        messagingSenderId: '329922709260'
    };
    initializedFirebaseApp = firebase.initializeApp(config);

    getAuth().onAuthStateChanged(async (user) => {
        if (user) {
            const token = await getAuth().currentUser.getIdToken(true);
            currentUser.token = token;
            localStorage.setItem('token', token);
            currentUser.googleDisplayName = user.displayName;
            currentUser.googlePhotoUrl = user.photoURL;
            currentUser.googleUid = user.uid;

            const mlUser = await checkForMLUser();
            currentUser.dbId = mlUser.id;
            currentUser.roles = mlUser.roles;
            userState.next(currentUser);
        } else {
            console.log('signed out');
        }
    });
};

export const signIn = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
        prompt: 'select_account'
    });

    const result = await signInWithPopup(auth, provider);
    return result;

    // try {
    //     await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    //     const auth = firebase.auth().signInWithPopup(provider);
    //     const result = await auth;
    //     return result;
    // } catch (e) {
    //     console.warn(e);
    //     return e;
    // }
};

export const signOut = async () => {
    await getAuth().signOut();
    userState.next(clearUser());
};

export const checkForMLUser = async () => {
    const response = await fetch(`${BASE_URL}/users/google`, {
        method: 'GET',
        ...getRequestHeader(true)
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error((await response.json()).message);
    }
};
