// initialState cannot be null, or else it will not be known
export const chaptersInitialState = {
  chapters: [],
  chosenChapter: {
    id: '',
    title: '',
    number: -1,
    tags: [],
    type: ''
  }
};

const chaptersActions = {
  'showChapter': (state, action) => ({
    ...state,
    chosenChapter: action.chapter
  }),
  'setChapters': (state, action) => ({
    ...state,
    chapters: action.chapters
  }),
  'updateChapterField': (state, action) => ({
    ...state,
    chosenChapter: {
      ...state.chosenChapter,
      [action.field]: action.value
    }
  }),
  'updateChapterFieldTag': (state, action) => ({
    ...state,
    chosenChapter: {
      ...state.chosenChapter,
      tags: [
        ...state.chosenChapter.tags.slice(0, action.index),
        ...state.chosenChapter.tags.slice(action.index + 1)
      ]
    }
  }),
  'clearChosenChapter': (state, action) => ({
    ...state,
    chosenChapter: {
      id: '',
      title: '',
      number: '',
      tags: [],
      type: ''
    }
  })
};

export const chaptersReducer = (state, action) => {
  return chaptersActions[action.type](state, action);
};
