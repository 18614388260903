import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Howl } from 'howler';

const AudioButton = ({ audioId }) => {

  const [ howlAudio, setHowlAudio ] = useState(null);

  const fetchAudio = useCallback(async () => {
    try {
      const result = await axios.get(`/api/audio/${audioId}`);
      setHowlAudio(new Howl({
        src: [result.data.file.ogg.dataString, result.data.file.mp4.dataString]
      }));
    } catch(err) {
      console.error(err);
    }
  }, [audioId]);

  const playAudio = () => {
    if (howlAudio) {
      howlAudio.play();
    }
  }

  useEffect(() => {
    fetchAudio().then();
  }, [fetchAudio]);

  return (<Button
      type="button"
      variant={'contained'}
      onClick={playAudio}
    >
      <FontAwesomeIcon
        icon={'play'}
      />
    </Button>);
};

export default AudioButton;