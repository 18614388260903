import {BehaviorSubject} from 'rxjs';

const initialStateUser = {
    token: undefined,
    googleDisplayName: undefined,
    googlePhotoUrl: undefined,
    googleUid: undefined,
    dbId: undefined,
    roles: undefined
};

export const userState = new BehaviorSubject({
    ...initialStateUser
});

export let user = {
    token: undefined,
    googleDisplayName: undefined,
    googlePhotoUrl: undefined,
    googleUid: undefined,
    dbId: undefined,
    roles: undefined
};

export const clearUser = async () => {
    user = {
        ...initialStateUser
    }
};