import React, {Component} from 'react';
import CharactersOverviewItem from './CharactersOverviewItem/CharactersOverviewItem';
import PropTypes from 'prop-types';
import CharactersOverviewContext from './CharactersOverviewContext';
import styles from './CharactersOverview.scss';
import ButtonToggleNewCharacterDialog from '../dialogs/NewCharacterDialog/ButtonToggleNewCharacterDialog';
import NewCharacterDialogProvider from '../dialogs/NewCharacterDialog/NewCharacterDialogProvider';
import NewCharacterDialogContext from '../dialogs/NewCharacterDialog/NewCharacterDialogContext';
import {isEqual, get} from 'lodash';
import AddCharacterOverviewItem from './AddCharacterOverviewItem/AddCharacterOverviewItem';

class CharactersOverview extends Component {
    static propTypes = {
        charactersList: PropTypes.array
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // TODO this whole rerendering should not be happening. Changes from form to store should not go back to form. subcribe once for initial values
        return !isEqual(get(this.props, 'chapter.id', undefined), get(nextProps, 'chapter.id', undefined)) ||
        !isEqual(this.props.charactersList.map(char => char.id),
            nextProps.charactersList.map(char => char.id));
    }

    render() {
        let items;
        if (this.props.charactersList && this.props.charactersList.length) {
            items = this.props.charactersList.map((char, index) => {
                console.log(char.id);
                return <CharactersOverviewItem key={char.id} data={char} index={index}></CharactersOverviewItem>
            })
        } else {
            items = <span>Leeg, niks gevonden</span>
        }

        return (
            <section>
                <NewCharacterDialogProvider>
                    <section className={styles.header}>
                        <h2>Karakter overzicht van gekozen hoofdstuk</h2>
                        { this.props.chapter && this.props.chapter.id ?
                                <ButtonToggleNewCharacterDialog/> : null
                        }
                    </section>
                    <NewCharacterDialogContext.Consumer>
                        {
                            context => {
                                return context.open ?
                                    <AddCharacterOverviewItem/> : null;
                            }
                        }
                    </NewCharacterDialogContext.Consumer>
                    {items}
                </NewCharacterDialogProvider>
            </section>
        )
    };
}

const CharactersOverviewWithContext = (props) => {
    return (
        <CharactersOverviewContext.Consumer>
            {
                context => {
                    return <CharactersOverview chapter={context.chapter} charactersList={context.characters} {...props}/>
                }
            }
        </CharactersOverviewContext.Consumer>
    )
};

export default CharactersOverviewWithContext;
