import React from 'react';
import styles from '../../App.module.scss';
import { CharactersOverviewProvider } from '../../components/charactersOverview/CharactersOverviewProvider';
import CharactersOverviewContext from '../../components/charactersOverview/CharactersOverviewContext';
import { ChaptersDataContext } from '../../app-data/ChapterData';
import ChapterSelectorForCharacters from '../../components/api/ChapterSelectorForCharacters';
import CharactersOverview from '../../components/charactersOverview/CharactersOverview';

export const CharactersManager = () => {
  return <section>
    <CharactersOverviewProvider>
      <CharactersOverviewContext.Consumer>
        {
          context => {
            return (
              <section className={styles.overviewContainer}>
                <ChaptersDataContext.Consumer>
                  {
                   chaptersData => <ChapterSelectorForCharacters
                     chaptersData={chaptersData}
                     onFetched={context.setCharactersAndChapter}/>
                  }
                </ChaptersDataContext.Consumer>
              </section>
           );
          }
        }
      </CharactersOverviewContext.Consumer>
      <CharactersOverview/>
    </CharactersOverviewProvider>
  </section>;
};
