import * as axios from 'axios';

export let axiosInstance = undefined;

export function initAxios() {
  axiosInstance = axios.create();
  axiosInstance.interceptors.request.use(function (config) {
    config.headers = {
      'x-access-token': localStorage.getItem('token')
    };
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
}

