import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import {BASE_URL} from '../../../app-data/ChapterData';
import classNames from 'classnames'
import styles from './AddSearchAudioDialog.scss';
import ButtonPlayAudio from "../../button-play-audio";
import NewAudioDialog from "../NewAudioDialog/NewAudioDialog";

export const getAudioFromSearch = async (searchTerm) => {
    const response = await fetch(`${BASE_URL}/audio/search`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({searchTerm: searchTerm})
    })
    if (response.ok) {
        return await response.json();
    } else {
        const errorObj = await response.json();
        throw new Error(errorObj.message);
    }
};

function Transition(props) {
    return <Slide direction="down" {...props} />
}

class AddSearchAudioDialog extends Component {
    state = {
        open: false,
        searchTerm: '',
        searchResults: [],
        showMore: false,
        newAudioDialogState: false
    };


    openDialog = () => {
      this.setState({open: true});
    };

    closeDialog = () => {
        this.setState({open: false});
    };

    newAudioDialogClose = () => {
        this.setState({newAudioDialogState: false});
    };

    setAudioId = (id) => () => {
        console.log(id)
        if (this.props.onSetAudioId) {
            this.props.onSetAudioId(id);
        }

        this.closeDialog();
        // and callBack pass audio ID to callback
    };

    searchAudio = (event) => {
        console.log('this works', event.key);
        if (event.key === 'Enter' || event.key === undefined) {
            console.log('searching for:' + this.state.searchTerm);
            getAudioFromSearch(this.state.searchTerm)
                .then(res => this.setState({searchResults: res}))
                .catch(err => console.error(err));
        }
    };

    handleSearchTerm = (event) => {
        this.setState({searchTerm: event.target.value })
    };

    componentDidUpdate() {
        let searchResultsHeight, dialogContentHeight;
        const searchResults = document.getElementsByClassName('searchResults');
        if (searchResults && searchResults.length > 0) {
            searchResultsHeight = searchResults[0].clientHeight;
        }

        let dialogContent = document.getElementsByClassName('dialogContent');
        if (dialogContent && dialogContent.length > 0) {
            dialogContentHeight = dialogContent[0].clientHeight;
        }

        if (dialogContentHeight && searchResultsHeight &&
            searchResultsHeight > dialogContentHeight) {
            if (!this.state.showMore) {
                this.setState({showMore: true})
            }
        }
    }

    openNewAudioDialogState = () => {
        this.setState({newAudioDialogState: true});
    }

    render() {
        let { open, searchResults, showMore } = this.state;
        return (
            <section>
                <Button variant="contained" onClick={this.openDialog}>SET AUDIO</Button>
                {/*// TODO: make portal!*/}
                <Dialog
                    open = {open}
                    TransitionComponent={Transition}
                >
                    <DialogTitle>Zoek naar audio of voeg een nieuwe toe</DialogTitle>
                    <DialogContent className={classNames('dialogContent')}>
                        <span>Zoek naar: </span>
                        <TextField
                            value={this.state.searchTerm}
                            onChange={this.handleSearchTerm}
                            onKeyPress={this.searchAudio}>
                        </TextField>
                        <Button variant="contained" onClick={this.searchAudio}>ZOEKEN</Button>
                        <Button variant="contained" onClick={this.openNewAudioDialogState}>NIEUW</Button>
                        <section className={classNames('searchResults')}>
                            {
                                searchResults.map(item => {
                                    return (
                                        <section key={item.id} className={classNames(styles.searchItem)}>
                                            <span>{item.name} {item.id}</span>
                                            <section className={styles.searchItemActions}>
                                                <ButtonPlayAudio fromAudioFile={item}/>
                                                <Button variant="contained" onClick={this.setAudioId(item.id)}>SET AUDIO</Button>
                                            </section>
                                        </section>
                                    )
                                })
                            }
                        </section>
                    </DialogContent>
                    {
                        showMore ? <p>SCROLL DOWN FOR MORE</p> : null
                    }
                    <DialogActions>
                        <Button onClick={this.closeDialog}>CANCEL</Button>
                    </DialogActions>
                </Dialog>
                <NewAudioDialog dialogState={this.state.newAudioDialogState} onCloseDialog={this.newAudioDialogClose}/>
            </section>
        )
    }
}

export default AddSearchAudioDialog;