import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { useOwnState } from '../../states/state';
import { chaptersService } from '../../containers/ChaptersManager/ChaptersService';

export const useChapters = () => {
  const [chapters, setChapters] = useState();
  useEffect(() => {
    chaptersService.getAllChapters().then(res => {
      setChapters(res);
    });
  }, []);

  return [chapters];
};

export const useChosenChapter = () => {
  const dispatch = useOwnState()[1];

  const setChosenChapter = (value) => {
    if (value) {
      dispatch({type: 'showChapter', chapter: value});
    } else {
      dispatch({type: 'clearChosenChapter'});
    }
  };
  return [setChosenChapter];
};

export const ChaptersAutocomplete = () => {
  const chapters = useChapters()[0];
  const [setChosenChapter] = useChosenChapter();

  return <Autocomplete
    options={chapters}
    getOptionLabel={option => option.title}
    style={{width: 300}}
    onChange={(event, value) => setChosenChapter(value)}
    renderInput={params => (
      <React.Fragment>
        <TextField {...params} label="Zoek naar hoofdstuk, tag werkt ook" variant="outlined" fullWidth/>
      </React.Fragment>
    )}
  />;
};
