import React, {Component} from 'react';
import withCharactersAPI from '../../api/withCharactersAPI';
import ChineseCharFormView from '../../views/ChineseCharFormView';
import CharactersOverviewContext from '../CharactersOverviewContext';

class CharactersOverviewItem extends Component {
    saveCharacter = (dataToSave) => {
        this.props.updateChineseCharacter(dataToSave);
    };

    deleteCharacter = (dataToDelete) => {
        this.props.deleteChineseCharacter(dataToDelete).then(res => console.log('it has been deleted!', res));
    };

    render() {
        console.log('rendering item');
        return (

            <CharactersOverviewContext.Consumer>
                {
                    context => {
                        this.charactersOverviewContext = context;
                        return <section className={'characters-overview-item'}>
                            <ChineseCharFormView
                                onCancel={() => {
                                }}
                                onSave={this.saveCharacter}
                                onDelete={this.deleteCharacter}
                                onViewChange={context.handleCharacterChange}
                                {...context.characters[this.props.index]}
                            />
                        </section>
                    }
                }
            </CharactersOverviewContext.Consumer>
        )
    }
}

export default withCharactersAPI(CharactersOverviewItem);