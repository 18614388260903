import {BASE_URL} from '../app-data/ChapterData';
import {axiosInstance} from '../services/axiosService';

const chineseChars = 'chinese-chars';

const createChineseChar = async (data) => {
  return axiosInstance.post(`${BASE_URL}/${chineseChars}`, data);
}

const updateChineseChar = async (data) => {
  // you only need to send the id of the chapter
  data.chapters = data.chapters.map(c => c.id);

  return axiosInstance.put(`${BASE_URL}/${chineseChars}/${data.id}`, data);
}

const deleteChineseChar = async (id) => {
  return axiosInstance.delete(`${BASE_URL}/${chineseChars}/${id}`);
}

export {
  createChineseChar,
  updateChineseChar,
  deleteChineseChar
}