/** @jsxImportSource @emotion/react */
import React from 'react';
import ReactDom from 'react-dom';
import { css } from '@emotion/react';

const modalStyling = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
 
  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
  
  .modal-content {
    padding: 16px;
    position: relative;
    z-index: 11; 
    background: #fff;
  }
}
`;

const Modal = ({ children, onClickBackground }) => {
  const modalsContainer = document.getElementById('modals-container');

  return ReactDom.createPortal(
    <div
      css={modalStyling}
    >
      <div
        className={'modal-background'}
        onClick={onClickBackground}
      >
      </div>
      <div className={'modal-content'}>
        {children}
      </div>
    </div>, modalsContainer);
};

export default Modal;