import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { CHAPTERS_DATA } from '../../app-data/ChapterData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import CHAPTER_DATA from '../app-data/ChapterData';

class ChapterSelector extends Component {
    state = {
        chapters: [],
        selected: 'kies'
    };

    componentDidMount() {
        this.props.chaptersData.chapters.subscribe(res => {
            this.setState({chapters: res})
          })
    }

    handleChange = event => {
        this.setState({ selected: event.target.value });
        const currentChapter = this.state.chapters.filter(chapter => chapter.id === event.target.value)[0];
        this.props.onSelected(currentChapter);
    }

    emptyChapters() {
        CHAPTERS_DATA.empty();
    }

    refreshChapters() {
        CHAPTERS_DATA.refresh();
    }

    render() {
        return (
            <section>
                <h2>Kies een hoofdstuk</h2>
                <FormControl>
                    <InputLabel>Hoofdstuk</InputLabel>
                    <Select
                        value={this.state.selected}
                        onChange={this.handleChange}
                    >
                        <MenuItem value='kies'>Kies een hoofdstuk</MenuItem>
                        {
                            this.state.chapters.map(chapter => {
                                return <MenuItem key={chapter.id} value={chapter.id}>{chapter.title}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <Button className="ui-button--icon" variant="contained" onClick={this.refreshChapters}>
                    <FontAwesomeIcon icon="sync"></FontAwesomeIcon>
                </Button>
            </section>
        );
    }
}

export default ChapterSelector;