import React from 'react';
import { useOwnState } from '../../../states/state';
import TextField from '@material-ui/core/TextField';
import { TagsHolder } from '../TagsHolder/TagsHolder';
import styles from './ChapterFormContainer.module.scss';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Button from '@material-ui/core/Button';
import { chaptersService } from '../../../containers/ChaptersManager/ChaptersService';

export const ChapterFormContainer = () => {
  const [{chaptersState: {chosenChapter}}] = useOwnState();
  return <section><h2>Hoofdstuk info:</h2>
    <span>Kies een hoofdstuk</span>
    <Form
      onSubmit={(values) => chaptersService.saveNewChapter(values)}
      initialValues={chosenChapter}
      mutators={{...arrayMutators}}
    >
      {
        (props) => (
          <ChapterForm {...props} />
        )
      }
    </Form>
  </section>;
};


export const ChapterForm = ({
    form: {
      mutators: {
        push, pop
      },
      change,
      reset
    },
    values
  }) => {
  const service = chaptersService;
  return <form className={styles.ChapterForm}>
    <Field name="title">{props => (
      <TextField className={styles.TextField}
                 label={'Titel'}
                 value={props.input.value}
                 onChange={props.input.onChange}
                 variant={'outlined'}
      />
    )}
    </Field>
    <Field name="number">{props => {
      return <TextField className={styles.TextField}
                        label={'Nummer'}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        variant={'outlined'}
      />;
    }}
    </Field>
    <TagsHolder fieldArrayName={'tags'} pushTag={push} popTag={pop}/>
    <section className={styles.formActions}>
      <Button variant={'contained'} onClick={() => {
        reset({})
      }}>VELDEN LEGEN</Button>
      <Button variant={'contained'} onClick={() => service.updateChapter(values)}>BIJWERKEN</Button>
      <Button variant={'contained'} onClick={() => service.saveNewChapter(values)}>NIEUWE AANMAKEN</Button>
    </section>
  </form>
};
