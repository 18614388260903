import { BehaviorSubject } from 'rxjs';
import React from 'react';

export const BASE_URL = '/api';
const BS_CHAPTERS = new BehaviorSubject([]);

export const CHAPTERS_DATA = {
    selectedChapter: undefined,
    chapters: BS_CHAPTERS,
    chaptersS: [],
    get: () => { 
        fetchChapterData();
        return CHAPTERS_DATA.chapters;
    },
    refresh: () => {
        CHAPTERS_DATA.get()
    },
    setChapter: (id) => {
        CHAPTERS_DATA.selectedChapter = id
    },
    empty: () => {
        CHAPTERS_DATA.chapters.next([]);
        CHAPTERS_DATA.chaptersS = [];
    }
}

function initChaptersData() {
    CHAPTERS_DATA.refresh();
    return CHAPTERS_DATA;
}

function fetchChapterData() {
    fetch(`${BASE_URL}/chapters`)
        .then(res => res.json())
        .then(res => {
            CHAPTERS_DATA.chapters.next(res) ;
            CHAPTERS_DATA.chaptersS = res;
        })
        .catch(err => console.error('fetch chapters error:', err));
}

export const ChaptersDataContext = React.createContext(initChaptersData());

// export CHAPTERS_DATA;
