import * as axios from 'axios';
import React from 'react';
import { axiosInstance } from '../../services/axiosService';
import { getChapterCharactersUrl } from '../../constants/apiUrls';

export const chaptersService = {
  async getChapterCharacters(chapterId) {
    const response = await axios.get(getChapterCharactersUrl(chapterId));
    return response.data;
  },
  async getAllChapters() {
    const response = await axios.get('/api/chapters');
    return response.data;
  },
  async getChapter(id) {
    const response = await axios.get(`/api/chapters/${id}`);
    return response.data;
  },
  async saveNewChapter(payload) {
    delete payload.id;
    try {
      const response = await axiosInstance.post(`/api/chapters`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateChapter(payload) {
    try {
      const response = await axiosInstance.put(`/api/chapters/${payload.id}`, payload);
      console.log('update success! refresh even');
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export const WithChapters = (props) => {
  return <React.Fragment>
    {props.children('test het')}
  </React.Fragment>
};
