import { Button } from '@mui/material';
import { Howl } from 'howler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useController } from 'react-hook-form';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

// The value is an array of audioIds, currently assuming we are working with this array
const AudioField = ({ control, name }) => {
  const {
    field: { value },
  } = useController({
    control,
    name,
  });

  const [howlAudio, setHowlAudio] = useState(null);

  const fetchAudio = useCallback(async () => {
    try {
      // in original its an array...
      const result = await axios.get(`/api/audio/${value[0]}`);
      setHowlAudio(new Howl({
        src: [result.data.file.ogg.dataString, result.data.file.mp4.dataString],
      }));
    } catch (err) {
      console.error(err);
    }
  }, [value])

  const playAudio = () => {
    if (howlAudio) {
      howlAudio.play();
    }
  };

  useEffect(() => {
    fetchAudio().then();
  }, [fetchAudio]);

  return <div>
    <Button
      variant={'contained'}
      onClick={playAudio}
    >
      <FontAwesomeIcon
        icon={'play'}
      />
    </Button>
  </div>;
};

export default AudioField;