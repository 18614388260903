import React, {Component} from 'react';
import {deleteChineseCharactersUrl, postChineseCharactersUrl, putChineseCharactersUrl} from '../../constants/apiUrls';
import styles from './withCharactersAPI.module.scss';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {getRequestHeader} from '../login/LoginForm';

export default function withCharactersAPI(WrappedComponent) {
    return class CharactersAPI extends Component {
        state = {
            open: false,
            vertical: 'top',
            horizontal: 'right',
            message: '',
            snackbarClasses: []
        };

        closeSnackbar = state => () => {
            this.setState({...state, open: false});
        };

        addChineseCharacter = state => async (data) => {
            const response = await fetch(postChineseCharactersUrl(), {
                body: JSON.stringify(data),
                method: 'POST',
                ...getRequestHeader(true)
            });

            if (response.ok) {
                this.setState({
                    snackbarClasses: [],
                    open: true,
                    message: 'Chinees karakter is toegevoegd!'
                });
                return await response.json();
            } else {
                const message = (await response.json()).message;
                this.setState({
                    ...state,
                    snackbarClasses: [`${styles.error}`],
                    open: true,
                    message: message
                });
                throw new Error(message);
            }
        };

        updateChineseCharacter = state => async (value) => {
            const body = {
                ...value,
                chapters: [
                    ...value.chapters.map(chapter => chapter.id)
                ]
            };
            delete body.id;

            const response = await fetch(putChineseCharactersUrl(value.id), {
                body: JSON.stringify(body),
                method: 'PUT',
                ...getRequestHeader(true)
            });

            if (response.ok) {
                this.setState({
                    ...state,
                    snackbarClasses: [`${styles.ok}`],
                    open: true,
                    message: 'Chinees karakter is aangepast!'
                });
                return await response.json();
            } else {
                const message = (await response.json()).message;
                this.setState({
                    ...state,
                    snackbarClasses: [`${styles.error}`],
                    open: true,
                    message: message
                });
                throw new Error(message);
            }
        };

        deleteChineseCharacter = state => async (characterId) => {
            const response = await fetch(deleteChineseCharactersUrl(characterId), {
                method: 'DELETE',
                ...getRequestHeader(true)
            });

            return await handleResponse(
                response,
                [
                    () => {
                        this.setState({
                            ...state,
                            snackbarClasses: [],
                            open: true,
                            message: 'Karakter is verwijder!'
                        });
                    }
                ],
                [
                    (errorMessage) => {
                        this.setState({
                            ...state,
                            snackbarClasses: [`${styles.error}`],
                            open: true,
                            message: errorMessage
                        });
                    }
                ]
            );
        };

        render() {
            const {vertical, horizontal, open, message, snackbarClasses} = this.state;
            const APImethods = {
                addChineseCharacter: this.addChineseCharacter(this.state),
                updateChineseCharacter: this.updateChineseCharacter(this.state),
                deleteChineseCharacter: this.deleteChineseCharacter(this.state)
            };

            return <section className={`${styles.container}`}>
                <WrappedComponent {...this.props} {...APImethods} />
                <Snackbar
                    autoHideDuration={60000}
                    anchorOrigin={{vertical, horizontal}}
                    open={open}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    onClose={this.closeSnackbar(this.state)}
                >
                    <SnackbarContent
                        className={snackbarClasses.join(' ')}
                        message={<span id="message-id">{message}</span>}
                    />
                </Snackbar>
            </section>
        }
    }
}

export const handleResponse = async (response, cbFunctionsSuccess, cbFunctionsError) => {
    if (response.ok) {
        cbFunctionsSuccess.forEach(cbFunction => {
            cbFunction();
        });
        return await response.json();
    } else {
        const message = (await response.json()).message;
        cbFunctionsError.forEach(cbFunction => {
            cbFunction(message);
        });
        throw new Error(message);
    }
};