/** @jsxImportSource @emotion/react */
import { Button, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import tw from 'twin.macro';

const container = css`
  ${tw`flex flex-row items-start`}
  
  .MuiFormControl-root {
    ${tw`block`}
  }
  
  .MuiTextField-root + .MuiTextField-root {
   ${tw`mt-4`}
  }
`
const buttonStyle = css`
  ${tw`inline-block ml-1 w-14 h-14 bg-blue-500`}
`;

const multiStyle = css`${tw`flex`}`;

const MultiTextField = (props) => {
  const { control, register } = useFormContext();
  const { name, label, formatFn } = props;

  const fieldArray  = useFieldArray({
    control,
    name: name,
  });

  const { fields, append, update } = fieldArray;

  const addField = () => {
    append({ value: '' });
  };

  const handleChange = (index) => (event) => {
    update(index, { ...fields[index], value: event.target.value })
  }

  useEffect(() => {
    if (fields.length === 0) {
      addField();
    }
  });

  return (
    <div
      css={container}
      className={'multi-text-field'}
    >
      <div>
        {fields.map((field, index) => {
          return (
              <div css={multiStyle} key={`${field.id}`}>
                <TextField
                    label={label}
                    autoComplete="off"
                    { ...register(`${name}.${index}.value`)}
                    onChange={handleChange(index)}
                />
                { formatFn && <Button key={`btn_${field.id}`} variant="contained" onClick={formatFn(index,update,fields)}>F</Button> }
              </div>
          );
        })}
      </div>

      <Button
        css={buttonStyle}
        type="button" variant="contained" onClick={addField}>+</Button>

    </div>
  );
};

export default MultiTextField;