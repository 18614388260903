import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import styles from './LoginForm.module.scss';
import { BASE_URL } from '../../app-data/ChapterData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signIn, signOut } from '../firebase/firebaseService';
import { userState } from '../../states/user';

export function getRequestHeader(withToken) {
  return {
    headers: {
      'content-type': 'application/json',
      ...((withToken) ? {'x-access-token': localStorage.getItem('token')} : {})
    }
  };
}

async function startGoogleLogin() {
  console.log('start');
  console.log(await signIn());
}

async function onSignOutClicked() {
  console.log('logout');
  await signOut();
}

class LoginForm extends Component {

  getMeUrl = `${BASE_URL}/me`;
  getTokenUrl = `${BASE_URL}/token`;
  isLoggedIn = false;

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isLoggedIn: false,
      googleDisplayName: undefined,
      googlePhotoUrl: undefined,
      roles: []
    };


  }

  componentDidMount() {
    const token = (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user'))['token'] : null;
    userState.subscribe(res => {
      if (res.dbId) {
        this.setState({
          isLoggedIn: true,
          googleDisplayName: res.googleDisplayName,
          googlePhotoUrl: res.googlePhotoUrl,
          roles: res.roles
        });
      } else {
        this.setState({
          username: '',
          password: '',
          isLoggedIn: false,
          googleDisplayName: undefined,
          googlePhotoUrl: undefined,
          roles: []
        });
      }
    });
    fetch(this.getMeUrl, {
      headers: {'x-access-token': token}
    })
      .then(res => {
        if (!res.ok) {
          throw Error(res.message);
        } else {
          return res;
        }
      })
      .then(res => res.json())
      .then(res => {
        console.log('setting the state');
        this.setState({isLoggedIn: true});
      }).catch(err => {
      console.warn(err);
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    console.log(this);
    console.log(JSON.stringify({
      email: this.state.username,
      password: this.state.password
    }));
    fetch(this.getTokenUrl, {
      body: JSON.stringify({
        email: this.state.username,
        password: this.state.password
      }),
      headers: {
        'content-type': 'application/json'
      },
      method: 'POST'
    })
      .then(res => res.json())
      .then(res => {
        console.log(res);
        const user = JSON.stringify({
          token: res.token
        });
        localStorage.setItem('user', user);
        this.setState({
          isLoggedIn: true
        });
      });
  };

  render() {
    return <section>
      {
        this.state.isLoggedIn &&
        <section>
          <section className={`${styles.loggedIn}`}>
            <img alt="profile" className={`${styles.googlePhoto}`} src={this.state.googlePhotoUrl}/>
            <i>Je bent ingelogd {this.state.googleDisplayName}</i>
          </section>
          {!this.state.roles.includes(100) &&
          <p>Je hebt geen rechten om data op te slaan!</p>
          }
          <section className={`${styles.signout}`}>
            <Button className={`${styles.buttonGoogle}`} type="button" variant="contained" onClick={onSignOutClicked}>
              <FontAwesomeIcon icon={['fas', 'sign-out-alt']}/>
              <span>SIGN OUT</span>
            </Button>
          </section>
        </section>
      }
      {
        !this.state.isLoggedIn &&
        (
          <div>
            <h2 className={`${styles.mustLogin}`}>Inloggen is nodig</h2>
            {/*<TextField onChange={this.handleChange('username')} className={`${styles.textfield} henk`} label="Username" id="username" type="text"/>*/}
            {/*<TextField onChange={this.handleChange('password')} className={styles.textfield} label="Password" id="password" type="password"/>*/}
            {/*<Button type="button" onClick={this.handleSubmit} variant="contained">Login</Button>*/}

            <Button className={`${styles.buttonGoogle}`} type="button" variant="contained"
                    onClick={startGoogleLogin}
            >
              <FontAwesomeIcon icon={['fab', 'google']}/>
              <span>LOGIN</span>
            </Button>
          </div>

        )
      }
    </section>;
  }
}

export default LoginForm;
