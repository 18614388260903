import React, {Component} from 'react';
import { PropTypes }  from 'prop-types';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import classNames from 'classnames/bind';

import styles from './ChineseCharFormview.module.scss';
import buttonStyles from '../../scss/buttons.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonPlayAudio from '../../components/button-play-audio';
import AddSearchAudioDialog from '../dialogs/AddSearchAudioDialog/AddSearchAudioDialog';
import { isMatch, isEqual } from 'lodash';

let cx = classNames.bind(styles);

class ChineseCharFormView extends Component {
    state = {
        id: this.props.id,
        characters: this.props.characters,
        pinyin: this.props.pinyin,
        dutch: this.props.dutch,
        audio: this.props.audio,
        chapters: this.props.chapters,
        tags: this.props.tags,
        type: this.props.type,
        isEditMode: this.props.isEditMode ? this.props.isEditMode : false
    };

    static defaultProps = {
        characters: '',
        pinyin: [''],
        dutch: [''],
        audio: [''],
        chapters: [''],
        tags: [''],
        type: ['character'],
        hasDeleteBtn: true
    };

    subject$ = new BehaviorSubject({})
        .pipe(debounceTime(500));

    subscription$ = this.subject$
        .subscribe(res => {
            if (!isMatch(this.props, this.filterCharacterDataFrom(this.state))) {
                if (this.props.onViewChange) {
                    this.props.onViewChange(this.filterCharacterDataFrom(this.state))
                }
            }
        });

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !isEqual(this.filterViewDataFrom(nextState), this.filterViewDataFrom(this.state));
    }

    filterCharacterDataFrom = (data) => {
        return {
            id: data.id,
            characters: data.characters,
            pinyin: data.pinyin,
            dutch: data.dutch,
            chapters: data.chapters,
            tags: data.tags,
            audio: data.audio,
            type: data.type
        };
    };

    filterViewDataFrom = (data) => ({
        ...this.filterCharacterDataFrom(data),
        isEditMode: data.isEditMode
    });

    componentWillUnmount() {
        this.subscription$.unsubscribe();
    }

    addToArray = (property) => () => {
        this.setState({
            [property]: [
                ...this.state[property],
                ''
            ]
        });
    };

    handleChange = (propertyToChange, nestedIndex) => (event) => {
        if (nestedIndex > -1) {
            this.setState({
                [propertyToChange]: [
                    ...this.state[propertyToChange].slice(0, nestedIndex),
                    event.target.value,
                    ...this.state[propertyToChange].slice(nestedIndex + 1)
                ]
            }, () => this.subject$.next(this.state));
        } else {
            this.setState({
                [propertyToChange]: event.target.value
            }, () => this.subject$.next(this.state));
        }
    };

    setAudioId = (audioId) => {
      this.setState({
          audio: [audioId]
      }, this.subject$.next(this.state));
    };

    toggleEditMode = () => {
        this.setState({isEditMode: !this.state.isEditMode});
        if (this.props.onToggle) { this.props.onToggle() };
    };

    componentWillReceiveProps(props) {
        this.setState(props);
    }

    onSave = () => {
        this.props.onSave(this.filterCharacterDataFrom(this.state));
    };

    onDelete = () => {
        this.props.onDelete(this.state.id);
    };

    render() {
        console.log('the state', this.state);
        return (
          <div>
              <section className={cx({
                  view: true,
                  height: this.state.isEditMode
              })}>
                  <TextField className={styles.field}
                             label="Characters"
                             disabled={!this.state.isEditMode}
                             value={this.state.characters}
                             onChange={this.handleChange('characters')}
                  />
                  <section className={styles.fieldContainer}>
                      {
                          this.state.pinyin.map((pinyin, index) => {
                              return (
                                <section key={index} className={styles.fieldWithButton}>
                                    <TextField
                                      className={styles.field}
                                      label="Voer pinyin in"
                                      disabled={!this.state.isEditMode}
                                      value={pinyin}
                                      onChange={this.handleChange('pinyin', index)}
                                    />
                                    <section>
                                        <Button
                                          variant="contained"
                                          className={buttonStyles.icon}
                                          onClick={this.addToArray('pinyin')}
                                        ><FontAwesomeIcon
                                          icon="plus"/></Button>
                                    </section>
                                </section>
                              )
                          })
                      }
                  </section>
                  <section className={styles.fieldContainer}>
                      {
                          this.state.dutch.map((dutch, index) => {
                              return (
                                <section key={index} className={styles.fieldWithButton}>
                                    <TextField className={styles.field}
                                               key={index}
                                               label="Nederlands"
                                               disabled={!this.state.isEditMode}
                                               value={dutch}
                                               onChange={this.handleChange('dutch', index)} />
                                    <section>
                                        <Button
                                          variant="contained"
                                          className={buttonStyles.icon}
                                          onClick={this.addToArray('dutch')}
                                        ><FontAwesomeIcon
                                          icon="plus"/></Button>
                                    </section>
                                </section>
                              )
                          })
                      }
                  </section>
                  <section className={styles.fieldContainer}>
                      {
                          this.state.tags.map((tag, index) => {
                              return (
                                <section key={index} className={styles.fieldWithButton}>
                                    <TextField className={styles.field}
                                               key={index}
                                               label="Tags"
                                               disabled={!this.state.isEditMode}
                                               value={tag}
                                               onChange={this.handleChange('tags', index)} />
                                    <section>
                                        <Button
                                          variant="contained"
                                          className={buttonStyles.icon}
                                          onClick={this.addToArray('tags')}
                                        ><FontAwesomeIcon
                                          icon="plus"/></Button>
                                    </section>
                                </section>
                              )
                          })
                      }
                  </section>
                  <section className={styles.audioButtons}>
                      <ButtonPlayAudio audioIds={this.state.audio}></ButtonPlayAudio>
                      <AddSearchAudioDialog onSetAudioId={this.setAudioId}/>
                  </section>
                  <section className={styles.itemActions}>
                      {
                          (!this.state.isEditMode) ?
                            (
                              <Button variant="contained" className={buttonStyles.icon}
                                      onClick={this.toggleEditMode}>
                                  <FontAwesomeIcon icon="edit"></FontAwesomeIcon>
                              </Button>
                            ) :
                            (
                              <React.Fragment>
                                  <Button variant="contained" className={buttonStyles.icon}
                                          onClick={this.onSave}>
                                      <FontAwesomeIcon icon="save"></FontAwesomeIcon>
                                  </Button>
                                  <Button variant="contained" className={buttonStyles.icon}
                                          onClick={this.toggleEditMode}>
                                      <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                                  </Button>
                              </React.Fragment>
                            )
                      }
                      {
                          (this.props.hasDeleteBtn) ?
                            <Button variant="contained" className={buttonStyles.icon} onClick={this.onDelete}>
                                <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                            </Button> : null
                      }
                  </section>
              </section>
              <section>
                  <TextField
                    label={'GIF url'}
                    disabled={!this.state.isEditMode}
                  />
              </section>
          </div>
        )
    }
}

ChineseCharFormView.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export default ChineseCharFormView;
