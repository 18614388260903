import React from 'react';
import { ChaptersAutocomplete } from '../../components/form/ChaptersAutocomplete';
import { ChapterFormContainer } from '../../components/form/ChapterForm/ChapterFormContainer';

export const ChaptersManager = () => {
  return <section>
    <ChaptersAutocomplete/>
    <ChapterFormContainer/>
  </section>;
};

