import React, { Component } from 'react';
import ChapterSelector from "../chapterSelector/ChapterSelector";
import {getChapterCharactersUrl} from "../../constants/apiUrls";
import { ChaptersDataContext } from '../../app-data/ChapterData';

class ChapterSelectorForCharacters extends Component {

    fetchCharactersForChapter = (chapter) => {
        if (this.context && chapter) {
            this.context.setChapter(chapter.id);
        } else {
            console.log('ik kom er niet');
        }

        if (!chapter) return;

        fetch(getChapterCharactersUrl(chapter.id),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                this.props.onFetched(res, chapter);
            })
            .catch(err => console.error(err));
    };

    render() {
        return (
            <ChaptersDataContext.Consumer>
                {
                    context => {
                        this.context = context;
                        return <ChapterSelector {...this.props}
                                                onSelected={this.fetchCharactersForChapter}/>
                    }
                }
            </ChaptersDataContext.Consumer>
        )
    }
}

export default ChapterSelectorForCharacters;
