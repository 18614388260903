import { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { chaptersService } from '../../containers/ChaptersManager/ChaptersService';
import { Autocomplete, TextField } from '@mui/material';

const ChapterSelector = forwardRef(({onResultsFound, onChapterSelected}, ref) => {
  const [chapters, setChapters] = useState([]);
  const [chosenChapter, setChosenChapter] = useState(null);
  const getChapters = async () => {
    const result = await chaptersService.getAllChapters();
    const autoCompleteList = result.map(chapter => {
      return {
        label: chapter.title,
        value: chapter.id
      }
    })
    setChapters(autoCompleteList);
  };

  const getChapterCharacters = async (chapterId) => {
    const result = await chaptersService.getChapterCharacters(chapterId);
    if (result) {
      onResultsFound(result);
    }
  }

  const refreshChapterCharacters = async () => {
    const result = await getChapterCharacters(chosenChapter);
    if (result) {
      onResultsFound(result);
    }
  }

  const showChapter = (event, value) => {
    if (!value) return;
    onChapterSelected(value.value);
    setChosenChapter(value.value);
    getChapterCharacters(value.value).then();
  }

  useImperativeHandle(ref, () => ({
    refreshChapterCharacters
  }));

  useEffect(() => {
    getChapters().then();
  }, [])

  return (
    <>
      <Autocomplete
        id={'chapter-selector'}
        disablePortal
        renderInput={params => <TextField label={'Chapters'} {...params} /> }
        options={chapters}
        onChange={showChapter}
      />
    </>
  );
});

export default ChapterSelector;