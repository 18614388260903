import { useState } from 'react';
import SingleTextField from '../FormFields/SingleTextField';
import { Button } from '@mui/material';
import Modal from './Modal';
import { useForm } from 'react-hook-form';
import { searchAudio } from '../../../services/audio';
import AudioButton from '../SetAudio/AudioButton';

const SearchAudioModal = ({ onBackgroundClick, setAudioCallback }) => {
  const [searchResult, setSearchResult] = useState([]);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      search: '',
    },
  });

  const searchSubmit = async (values, event) => {
    console.log('value to search', values);
    const result = await searchAudio(values.search);
    setSearchResult(result.data);
    console.log('in search audio modal', result);
  };

  const setAudio = (audioId) => () => {
    setAudioCallback(audioId);
  };

  return <Modal onClickBackground={onBackgroundClick}>
    <form id={'search-audio'}
          onSubmit={handleSubmit(searchSubmit)}
    >
      <SingleTextField
        control={control}
        name={'search'}
      />
      <Button
        form={'search-audio'}
        type="submit"
      >
        SEARCH
      </Button>
    </form>
    <ul>
      {searchResult.map(audio => {
        return (
          <li
            key={audio.id}
          >
            <Button variant="contained"
                    onClick={setAudio(audio.id)}
            >USE {audio.name}</Button>
            <AudioButton audioId={audio.id} />
          </li>
        );
      })}
    </ul>
  </Modal>;
};

export default SearchAudioModal;