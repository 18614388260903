import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ButtonToggleNewCharacterDialog.scss';
import NewCharacterDialogContext from './NewCharacterDialogContext';

export class ButtonToggleNewCharacterDialog extends Component {
    render() {
        return (
            <section>
                <NewCharacterDialogContext.Consumer>
                    {
                        context => (
                            <section>
                                <Button onClick={context.toggle} variant="contained">
                                    <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                                    <span className={styles.character}>Chinese Karakter</span>
                                </Button>
                            </section>
                        )
                    }
                </NewCharacterDialogContext.Consumer>
            </section>
        )
    }
}

export default ButtonToggleNewCharacterDialog;