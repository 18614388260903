import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import styles from './TagsHolder.module.scss';
import Button from '@material-ui/core/Button';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';

export const TagsHolder = ({
  tags,
  dispatch,
  fieldArrayName,
  pushTag,
  popTag
  }) => {
  const [currentTag, changeTag] = useState('');
  return <React.Fragment>
    <TextField className={styles.TextField}
      label={'Tag toevoegen'}
      value={currentTag}
      onChange={(event) => changeTag(event.target.value)}
      variant={'outlined'}
    />
    <Button className={styles.ButtonAddTag} variant="contained" onClick={() => pushTag('tags', currentTag)}>+ TAG</Button>
    <section className={styles.ChipsContainer}>
      <FieldArray name={fieldArrayName}>{
        ({fields}) => {
          return fields.map((name, index) => {
            return <Field name={name} key={name}>{(props) => {
              return <React.Fragment>
                <Chip className={styles.Chip} label={props.input.value} key={props.input.value} onDelete={() => popTag('tags', index)}/>
              </React.Fragment>
            }}
            </Field>
          })
        }
      }
      </FieldArray>
    </section>
  </React.Fragment>
};
