import React, { Component } from 'react';
import {BASE_URL} from "../../app-data/ChapterData";
import {getRequestHeader} from "../login/LoginForm";
// import PropTypes from 'prop-types';

function withAudioAPI(WrappedComponent, testRef) {
    return class extends Component {
        postNewAudio = async (data) => {
            const response = await fetch(`${BASE_URL}/audio`, {
                body: JSON.stringify(data),
                method: 'POST',
                ...getRequestHeader(true)
            })
            if (response.ok) {
                return await response.json();
            } else {
                throw new Error((await response.json()).message);
            }
        };

        sayHello = () => {
            console.log('im saying hello');
        };

        // static propTypes = {
        //     test: PropTypes.string.isRequired
        // };

        render() {


            const APImethods = {
                postNewAudio: this.postNewAudio
            };

            return (
                <WrappedComponent {...this.props} {...APImethods}/>
            )
        }
    }
}

export default withAudioAPI;