import { TextField } from '@material-ui/core';
import { useController } from 'react-hook-form';

const SingleTextField = ({
  control,
  label,
  name,
}) => {
  const {
    field: { onChange, value },
  } = useController({
      control,
      name,
    },
  );

  return <TextField
    label={label}
    variant={'outlined'}
    name={name}
    value={value}
    onChange={onChange}
  />;
};

export default SingleTextField;