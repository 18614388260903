import React, { Component } from 'react';
import CharactersOverviewContext from './CharactersOverviewContext';

export class CharactersOverviewProvider extends Component {
    state = {
        characters: [],
        chapter: null,
        setCharacters: (res) => {
            this.setState({characters: res})
        },
        setCharactersAndChapter: (res, chapter) => {
            this.setState({characters: res, chapter: chapter})
        },
        refreshCurrentList: () => {
            console.log('refreshing current List');
        },
        handleCharacterChange: (charToUpdate) => {
            console.log('hiero', charToUpdate);
            const index = this.state.characters.findIndex(char => char.id === charToUpdate.id);

            this.setState({
                characters: [
                    ...this.state.characters.slice(0, index),
                    {
                        ...charToUpdate
                    },
                    ...this.state.characters.slice(index + 1)
                ]
            })
        },
        setAudioId: (characterId) => (audioId) => {
            const index = this.state.characters.findIndex(char => char.id === characterId);
            this.setState({
                characters: [
                    ...this.state.characters.slice(0, index),
                    {
                        ...this.state.characters[index],
                        audio: [audioId]
                    },
                    ...this.state.characters.slice(index + 1)
                ]
            })
        },
        toggleEditMode: (characterId) => () => {
            const index = this.state.characters.findIndex(char => char.id === characterId);
            this.setState({
                characters: this.editCharacterInList(
                    index,
                    {
                        ...this.state.characters[index],
                        editMode: !this.state.characters[index].editMode
                    }
                )
            })
        }
    };

    editCharacterInList = (index, edited) => {
        return [
            ...this.state.characters.slice(0, index),
            {
                ...edited
            },
            ...this.state.characters.slice(index + 1)
        ]
    };

    render() {
        return <CharactersOverviewContext.Provider value={this.state}>
            {this.props.children}
        </CharactersOverviewContext.Provider>
    }
}
