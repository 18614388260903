import React from 'react';
import { Link } from '@reach/router';
import styles from './Nav.module.scss';

const NavLink = (props) => {
  const isActive = ({isCurrent}) => {
    let navLinkStyle = styles.navLink;
    if (isCurrent) {
      navLinkStyle = navLinkStyle.concat(' ' + styles.navLinkActive);
    }
    return {
      className: navLinkStyle
    }
  };

  return <Link
    {...props}

    className={styles.navLink}
    getProps={isActive}
  />;
};

export const Nav = () => {
  return <section className={styles.navigation}>
    <NavLink to="/characters-manager/characters">Characters manager</NavLink>
    <NavLink to="/characters-manager/chapters">Chapter manager</NavLink>
    <NavLink to="/characters-manager/new-characters">NEW characters manager</NavLink>
  </section>;
};
