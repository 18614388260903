import React, { Component } from 'react';
import { Howl } from 'howler';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../scss/buttons.scss';

class ButtonPlayAudio extends Component {
    state = {
        audioFile: ''
    };
    constructor(props) {
        super(props);
        this.playAudio = this.playAudio.bind(this);
    }

    componentDidMount() {
        const audioFile = this.props.fromAudioFile;
        if (this.props.fromAudioFile) {
            const soundArray = [
                audioFile.file.ogg.dataString, audioFile.file.mp4.dataString
            ];
            this.setState({
                audioFile: new Howl({
                    src: soundArray
                })
            })
        } else {
            this.fetchAudio(this.props.audioIds[0]);
        }
    }

    componentWillReceiveProps(props) {
        if (props.audioIds && props.audioIds.length > 0) {
            this.fetchAudio(props.audioIds[0]);
        }
    }

    render() {
        return <Button className={classNames(styles.icon)} variant="contained" onClick={this.playAudio}>
            <FontAwesomeIcon icon="play"/>
        </Button>;
    }

    playAudio() {
        if (this.state.audioFile && !this.state.audioFile.playing()) {
            this.state.audioFile.play();
        }
    }

    reloadAudio = () => {
      this.state.audioFile.load();
    };

    fetchAudio = (id) => {
        fetch(`/api/audio/${id}`)
            .then(res => res.json())
            .then(res => {
                const soundArray = [
                    res.file.ogg.dataString, res.file.mp4.dataString
                ];
                this.setState({
                    audioFile: new Howl({
                        src: soundArray
                    })
                }, this.reloadAudio);
            })
            .catch(err => console.warn(err));
    }
}

export default ButtonPlayAudio;