import React from 'react';

const CharactersOverviewContext = React.createContext({
    characters: [],
    editableCharacters: [],
    setAudioId: () => {},
    setCharacters: () => {},
    toggleEditMode: () => {},
    handleCharacterChange: () => {}
    // updateCharacter: (index, propertyToChange, value) => {
    //     CHARACTERS_OVERVIEW_STATE.characters[index][propertyToChange] = value;
    // },
    // setAudioCharacter: (index, audioId, value) => {
    //     CHARACTERS_OVERVIEW_STATE.characters[index].audio = [value];
    // },
    // toggleEditable: (index) => {
    //     CHARACTERS_OVERVIEW_STATE.characters[index].editable = !CHARACTERS_OVERVIEW_STATE.characters[index].editable;
    // }
});

export default CharactersOverviewContext;