import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import withAudioAPI from "../../api/withAudioAPI";

// creates file and gives back id and audiofile
class NewAudioDialog extends Component {
    state = {
        open: false,
        oggFile: '',
        oggFileName: '',
        oggFileString: '',
        mp4File: '',
        mp4FileName: '',
        mp4FileString: '',
        name: '',
        tags: []
    }

    componentWillReceiveProps(props) {
        if (props.dialogState) {
            this.setState({open: props.dialogState});
        }
    }

    handleChange = (prop) => (event) => {
        let file = event.target.files[0];
        let fileString;
        makeFileString(file).then(res => {
            fileString = res.fileString;
            this.setState({
                name: file.name.slice(0, -4),
                [prop + 'String']: fileString,
                [prop + 'Name']: file.name
            });
        });
        this.setState({[prop]: file})
    };

    addTag = () => {
        this.setState({
            tags: [
                ...this.state.tags,
                ''
            ]
        })
    };

    handleTagChange = key => (event) => {
        this.setState({
            tags: [
                ...this.state.tags.slice(0, key),
                event.target.value,
                ...this.state.tags.slice(key + 1)
            ]
        })
    }

    closeDialog = () => {
        this.setState({open: false});
        this.props.onCloseDialog(false);
    };

    saveAudio = () => {
        const { name, oggFileName, oggFileString,
            mp4FileName, mp4FileString, tags} = this.state;
        const audioToBeSaved = {
            name: name,
            file: {
                ogg: {
                    name: oggFileName,
                    dataString: oggFileString
                },
                mp4: {
                    name: mp4FileName,
                    dataString: mp4FileString
                }
            },
            tags: tags.filter(tag => tag !== '')
        };
        console.log(audioToBeSaved);
        if (this.props.postNewAudio) {
            this.props.postNewAudio(audioToBeSaved)
                .then(res => {
                    console.log(res);
                    if (this.props.onAudioSaved) {
                        this.props.onAudioSaved(res.inserted)
                    } else {
                        console.warn('no callback for saving on audio')
                    }
                })
                .catch(err => {
                    console.error(err);
                    // TODO show snackbar on error
                });
        }
    };

    testIt = () => {
        console.log('hallo dit zie ik denk ik niet');
        this.props.onTest();
    };

    render() {
        return (
            <Dialog open={this.state.open}>
                <DialogTitle>Voeg nieuwe audio toe</DialogTitle>
                <DialogContent>
                    <section>
                        <label>Ogg audio</label>
                        <input type="file" onChange={this.handleChange('oggFile')}/>
                    </section>
                    <section>
                        <label>Mp4 audio</label>
                        <input type="file" onChange={this.handleChange('mp4File')}/>
                    </section>
                    <section>
                        {
                            this.state.tags.map((tag, key) => {
                                return <TextField key={key} onChange={this.handleTagChange(key)}></TextField>
                            })
                        }
                        <Button variant="contained" onClick={this.addTag}>ADD TAG</Button>
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.testIt}>TEST</Button>
                    <Button onClick={this.closeDialog}>ANNULEREN</Button>
                    <Button onClick={this.saveAudio}>OPSLAAN</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

function makeFileString(file) {
    return new Promise((resolve, reject) => {
        let fileString = '';

        const fileReader  = new FileReader();
        fileReader.onloadend = () => {
            fileString = fileReader.result;
            resolve({
                type: file.type,
                size: file.size,
                name: file.name,
                fileString: fileString
            });
        };
        if (file) {
            fileReader.readAsDataURL(file);
        }
    });
};

export default withAudioAPI(NewAudioDialog, 'onTest');