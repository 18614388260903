import axios from 'axios';
import { BASE_URL } from '../app-data/ChapterData';

const searchAudio = async (searchTerm) => {
  const result = await axios.post(`${BASE_URL}/audio/search`, {searchTerm});
  return result
}

export {
  searchAudio
}