import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import {initFirebase} from './components/firebase/firebaseService';

// init firebase
initFirebase().then();

ReactDOM.render(<App/>, document.getElementById('root'));
// registerServiceWorker();
