import { chaptersInitialState, chaptersReducer } from '../containers/ChaptersManager/ChaptersState';

export const initialState = {
  chaptersState: {...chaptersInitialState}
};

export const reducer = (state, action) => ({
  chaptersState: chaptersReducer(state.chaptersState, action)
});

